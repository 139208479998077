import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sparsity Aware PCA`}</h1>
    <ul>
      <li parentName="ul">{`A naive implementation of `}<a parentName="li" {...{
          "href": "/PCA",
          "title": "PCA"
        }}>{`PCA`}</a>{` for Polis data would have the problem of participants who respond to many questions getting projected out `}<strong parentName="li">{`further`}</strong>{` out from the center of the `}<a parentName="li" {...{
          "href": "/opinion-space",
          "title": "opinion space"
        }}>{`opinion space`}</a>{` than those who answer fewer questions.`}</li>
      <li parentName="ul">{`We'd like to be able to adjust the extent to which we project participants according to how much information we have about them. However, not all comments should be weighted equally. Any given comment can have more or less of an effect on how far out a comment gets projected, and in which direction. So we should ideally scale our projections not just according to how many questions a participant responds to, but also according to the `}<a parentName="li" {...{
          "href": "/PCA-loadings",
          "title": "PCA loadings"
        }}>{`PCA loadings`}</a>{` of the specific comments which they responded to.`}</li>
      <li parentName="ul">{`Math`}
        <ul parentName="li">
          <li parentName="ul">{`Let `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.59444em",
                      "verticalAlign": "-0.15em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mord mathbf",
                        "style": {
                          "marginRight": "0.01597em"
                        }
                      }}>{`w`}</span></span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.31166399999999994em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`i`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.15em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span>{` be the `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.65952em",
                      "verticalAlign": "0em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`i`}</span></span></span></span>{`th principle component in the `}<a parentName="li" {...{
              "href": "/PCA",
              "title": "PCA"
            }}>{`PCA`}</a>{`, and `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.730548em",
                      "verticalAlign": "-0.286108em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mord mathbf",
                        "style": {
                          "marginRight": "0.01597em"
                        }
                      }}>{`w`}</span></span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.311664em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal mtight"
                                  }}>{`i`}</span><span parentName="span" {...{
                                    "className": "mpunct mtight"
                                  }}>{`,`}</span><span parentName="span" {...{
                                    "className": "mord mathnormal mtight",
                                    "style": {
                                      "marginRight": "0.05724em"
                                    }
                                  }}>{`j`}</span></span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.286108em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span>{` be the `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.85396em",
                      "verticalAlign": "-0.19444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.05724em"
                    }
                  }}>{`j`}</span></span></span></span>{`th entry in that vector, corresponding to comment `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.716668em",
                      "verticalAlign": "-0.286108em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`c`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.311664em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginLeft": "0em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.05724em"
                                  }
                                }}>{`j`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.286108em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span>{`.`}</li>
          <li parentName="ul">{`For a given participant `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.625em",
                      "verticalAlign": "-0.19444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`p`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.33610799999999996em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginLeft": "0em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.03148em"
                                  }
                                }}>{`k`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.15em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span>{`, let `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.83333em",
                      "verticalAlign": "-0.15em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.07153em"
                      }
                    }}>{`C`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.33610799999999996em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginLeft": "-0.07153em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.03148em"
                                  }
                                }}>{`k`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.15em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span>{` be the set of comments said participant responded to with a non-pass vote.`}</li>
          <li parentName="ul">{`We now compute `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.58056em",
                      "verticalAlign": "-0.15em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.03588em"
                      }
                    }}>{`v`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.33610799999999996em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.5500000000000003em",
                                "marginLeft": "-0.03588em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.03148em"
                                  }
                                }}>{`k`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.15em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2777777777777778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2777777777777778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1.24703em",
                      "verticalAlign": "-0.49703em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mop"
                  }}><span parentName="span" {...{
                      "className": "mop op-symbol small-op",
                      "style": {
                        "position": "relative",
                        "top": "-0.0000050000000000050004em"
                      }
                    }}>{`∑`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.17862099999999997em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.40029em",
                                "marginLeft": "0em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal mtight",
                                    "style": {
                                      "marginRight": "0.05724em"
                                    }
                                  }}>{`j`}</span><span parentName="span" {...{
                                    "className": "mrel mtight"
                                  }}>{`∈`}</span><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mathnormal mtight",
                                      "style": {
                                        "marginRight": "0.07153em"
                                      }
                                    }}>{`C`}</span><span parentName="span" {...{
                                      "className": "msupsub"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.3448em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.3487714285714287em",
                                                "marginLeft": "-0.07153em",
                                                "marginRight": "0.07142857142857144em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "2.5em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "sizing reset-size3 size1 mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mathnormal mtight",
                                                  "style": {
                                                    "marginRight": "0.03148em"
                                                  }
                                                }}>{`k`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.15122857142857138em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                    "className": "mpunct mtight"
                                  }}>{`,`}</span><span parentName="span" {...{
                                    "className": "mord mathnormal mtight"
                                  }}>{`i`}</span><span parentName="span" {...{
                                    "className": "mrel mtight"
                                  }}>{`∈`}</span><span parentName="span" {...{
                                    "className": "mord mathnormal mtight"
                                  }}>{`L`}</span><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mathnormal mtight"
                                    }}>{`c`}</span><span parentName="span" {...{
                                      "className": "msupsub"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.3280857142857143em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.357em",
                                                "marginLeft": "0em",
                                                "marginRight": "0.07142857142857144em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "2.5em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "sizing reset-size3 size1 mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mathnormal mtight",
                                                  "style": {
                                                    "marginRight": "0.05724em"
                                                  }
                                                }}>{`j`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.2818857142857143em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.49703em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span>{`, where `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.68333em",
                      "verticalAlign": "0em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`L`}</span></span></span></span>{` is the set of PC dimensions being projected to (typically `}<span parentName="li" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mopen"
                  }}>{`{`}</span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`1`}</span><span parentName="span" {...{
                    "className": "mpunct"
                  }}>{`,`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.16666666666666666em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`2`}</span><span parentName="span" {...{
                    "className": "mclose"
                  }}>{`}`}</span></span></span></span>{`)`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      